import { useCallback, useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import JobCard from '../../components/elements/JobCard'
import { withFirebase } from '../../firebase'
import { getJobsV2, getJobsV2Counter, getJobsV3 } from '../../db/job'
import FilterBar from './FilterBar'
import { useSearchParams } from 'react-router-dom'
import { orderBy, where } from 'firebase/firestore'
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks'
import { DateTime } from 'luxon'
import SearchBanner from './SearchBanner'
import { getIndustryKeys } from '../../constants/Industries'

const INDUSTRIES = getIndustryKeys()

const JobsList = (props) => {
  const db = props.firebase.getdb()
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [limitValue, setLimitValue] = useState()
  const [sortBy] = useState(0)
  const [lastVisible, setLastVisible] = useState(null)
  const size = useWindowSize()
  const scroll = useWindowScroll()
  const [yOffset, setYOffset] = useState(0)
  const [documentCounter, setDocumentCounter] = useState(0)
  const [queryFilters, setQueryFilters] = useState()
  const [query, setQuery] = useState()
  const [jobPostedCounters, setJobPostedCounters] = useState({})
  const [firstTime, setFirstTime] = useState(true)
  const [industryCounters, setIndustryCounters] = useState()

  useEffect(() => {
    // INFINITE SCROLL LOGIC
    if (!size) {
      return
    }
    setLimitValue(Math.round((size.width / 400) * (size.height / 400)))
    if (!yOffset) {
      setYOffset(size.height)
    }
  }, [size])

  useEffect(() => {
    if (scroll) {
      onScroll()
    }
  }, [scroll])

  const [loadingFilters, setLoadingFilters] = useState(false)

  useEffect(() => {
    if (!searchParams) {
      return
    }

    if (!firstTime) {
      return
    }

    let item = {}
    const state = searchParams.get('state')
    if (state) {
      item['state'] = state
    }

    const city = searchParams.get('city')
    if (city) {
      item['city'] = city
    }

    const search = searchParams.get('search')
    if (search) {
      item['search'] = search
    }

    const jobPosted = searchParams.get('posted-day')
    if (jobPosted) {
      item['posted-day'] = +jobPosted
    }

    const industries = searchParams?.get('industries')?.split(',') ?? null
    if (industries) {
      item['industries'] = industries
    }
    setQueryFilters(item)
    setFirstTime(false)
  }, [searchParams])

  const onScroll = useCallback(async () => {
    if (scroll[0].y > yOffset - size.height / 1.4 && lastVisible) {
      setYOffset(yOffset + size.height)
      await onLoadMore()
    }
  }, [scroll, yOffset, lastVisible])

  const fetchJobs = useCallback(
    async (currentLastVisible, avoidParamUpdate = false) => {
      if (loadingFilters) {
        return
      }

      if (!currentLastVisible) {
        setLoading(true)
      }

      if (!currentLastVisible) {
        setYOffset(size.height)
      }

      if (!limitValue) {
        console.log('no limit value set')
        return
      }

      try {
        // const sort = sortBy ? 'asc' : 'desc'
        const state = queryFilters['state']
        const search = queryFilters['search']
        const city = queryFilters['city'] ?? null
        const jobPosted = queryFilters['posted-day'] ?? null
        const industries = queryFilters['industries'] ?? []
        console.log('industries:', industries)
        const filters = [
          where('status', '==', 'published'),
          where('expiration', '>=', new Date()),
        ]
        const lastQ = { ...queryFilters }
        if (city && state) filters.push(where('city', '==', city))
        if (state) filters.push(where('state', '==', state))
        
        const jobPostedData = await getJobPostedCounters(
          [...filters],
          [0, 1, 7, 30],
        )

        if (jobPosted) {
          const plus = { days: jobPosted }
          const date = DateTime.now().minus(plus).toJSDate()
          filters.push(where('createdAt', '>', date))
        }
        await getIndustryCounters([...filters], undefined)

        if (industries.length > 0) {
          filters.push(where('industry', 'in', industries))
          lastQ['industries'] = industries.toString()
        }

        setQuery(filters)

        filters.push(orderBy('createdAt', 'desc'))

        // if (!currentLastVisible) {
        //   setDocumentCounter(counter)
        // }

        // const latestItems = currentLastVisible
        //   ? [...jobs, ...items]
        //   : [...items]

        // setJobs(latestItems)
        // setJobPostedCounters(jobPostedData)
        // setLastVisible(newLastVisible)
        // setLoading(false)

        // setSearchParams((prevParams) => lastQ)

        let filteredItems = [];
        let tmpLastVisible = 0;
        let totalCount = 0;
        if (search) {
          console.log('search:', currentLastVisible)
          const { items, newLastVisible, counter } = await getJobsV3(
            db,
            filters,
            currentLastVisible,
            'desc',
          )
          const searchLower = search.toLowerCase();
          filteredItems = items.filter((item) =>
            item.description?.toLowerCase().includes(searchLower)
          );
          tmpLastVisible = newLastVisible
          totalCount = filteredItems.length;
        } else {
          console.log('no search:', currentLastVisible)
          const { items, newLastVisible, counter } = await getJobsV2(
            db,
            filters,
            limitValue,
            currentLastVisible,
            'desc',
          )
          filteredItems = items;
          tmpLastVisible = newLastVisible;
          totalCount = counter;
          console.log('counter:', totalCount)
        }
        console.log('items:', filteredItems);
        if (!currentLastVisible) {
          setDocumentCounter(totalCount);
        }

        const latestItems = currentLastVisible ? [...jobs, ...filteredItems] : [...filteredItems];

        setJobs(latestItems);
        setJobPostedCounters(jobPostedData);
        setLastVisible(tmpLastVisible);
        setLoading(false);

        setSearchParams((prevParams) => lastQ);
      } catch (error) {
        console.error('Error fetching jobs:', error)
      }      
    },
    [
      queryFilters,
      db,
      sortBy,
      limitValue,
      searchParams,
      jobs,
      lastVisible,
      size,
    ],
  )

  useEffect(() => {
    if (queryFilters !== undefined) {
      fetchJobs(null)
    }
  }, [queryFilters])

  const getIndustryCounters = useCallback(async (filters, industries) => {
    const finalIndustries =
      industries && industries.length ? industries : INDUSTRIES

    const promises = finalIndustries.map(async (industry) => {
      const filtersCopy = [...filters]
      filtersCopy.push(where('industry', '==', industry))
      const counter = await getJobsV2Counter(db, filtersCopy)
      return { [industry]: counter }
    })

    const result = await Promise.all(promises)

    const industryCounters = INDUSTRIES.reduce((acc, industry, index) => {
      acc = { ...acc, ...result[index] }
      return acc
    }, {})

    setIndustryCounters(industryCounters)
  }, [])

  const getJobPostedCounters = useCallback(async (filters, finalJobPosted) => {
    const promises = finalJobPosted.map(async (jobPosted) => {
      const filtersCopy = [...filters]
      const plus = { days: jobPosted }
      const date = DateTime.now().minus(plus).toJSDate()
      if (jobPosted !== 0) {
        filtersCopy.push(where('createdAt', '>', date))
      }
      const counter = await getJobsV2Counter(db, filtersCopy)
      return { [jobPosted]: counter }
    })

    const result = await Promise.all(promises)
    return INDUSTRIES.reduce((acc, industry, index) => {
      acc = { ...acc, ...result[index] }
      return acc
    }, {})
  }, [])

  const renderJobs = () => {
    return (
      <div className="row">
        {loading && <span>Loading Jobs... </span>}
        {!loading &&
          jobs.map((row, i) => {
            return <JobCard key={row.id} job={row} />
          })}
        {!loading && jobs.length === 0 && (
          <span>There is no jobs matching the filters.</span>
        )}
      </div>
    )
  }

  const onSubmit = useCallback(
    (search, state) => {
      let currentFilters = queryFilters
      const newQueryParams = {}
      if (state) {
        newQueryParams['state'] = state
      } else {
        delete currentFilters['state']
      }
      if (search) {
        newQueryParams['search'] = search
      } else {
        delete currentFilters['search']
      }
      const last = { ...currentFilters, ...newQueryParams }

      console.log('qq 2')
      setQueryFilters(last)
    },
    [queryFilters],
  )

  const onChangeFilters = useCallback(
    (filters) => {
      if (queryFilters['search']) {
        filters['search'] = queryFilters['search']
      }

      if (queryFilters['state']) {
        filters['state'] = queryFilters['state']
      }

      if (firstTime) {
        setFirstTime(false)
        return
      }

      if (JSON.stringify(queryFilters) !== JSON.stringify(filters)) {
        setQueryFilters({ ...filters })
      }
    },
    [queryFilters],
  )

  const onLoadMore = useCallback(async () => {
    await fetchJobs(lastVisible)
  }, [lastVisible])

  if (!queryFilters) {
    return null
  }

  return (
    <Layout>
      <SearchBanner onSubmit={onSubmit} queryFilters={query} />
      <section className="section-box mt-30">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
              <div className="content-page">
                {jobs && jobs.length > 0 && !!documentCounter && !loading && (
                  <h5 className="text-end mb-30">
                    <strong> {documentCounter}</strong> jobs found
                  </h5>
                )}
                {renderJobs()}
              </div>              
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="sidebar-shadow none-shadow mb-30">
                <FilterBar
                  disabled={loading}
                  jobPostedCounters={jobPostedCounters}
                  industryCounters={industryCounters}
                  onChange={onChangeFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withFirebase(JobsList)
