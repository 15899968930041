import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import CategorySlider from './../../components/sliders/Category'

import React from 'react'
import { withFirebase } from '../../firebase'
import HomeBanner from './HomeBanner'

const Home = (props) => {
  const user = props.user
  const firebase = props.firebase

  const db = props.firebase.getdb()

  return (
    <>
      <Layout user={user} firebase={firebase}>
        <section className="section-box mt-20">
          <div className="banner-hero hero-1">
            <div className="bg-square-design" />
            <div className="banner-inner">
              <div className="row">
                <HomeBanner />
                <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
                  <div className="banner-imgs wow animate__animated animate__fadeIn">
                    <div className="block-1">
                      <img
                        className="img-responsive brr"
                        alt="findAJobUSA"
                        src="assets/imgs/page/homepage1/home-banner-1.jpg"
                      />
                    </div>
                    <div className="block-2">
                      <img
                        className="img-responsive"
                        alt="findAJobUSA"
                        src="assets/imgs/page/homepage1/banner2.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-20" />
        <section className="section-box mt-80">
          <div className="section-box wow animate__animated animate__fadeIn">
            <div className="container">
              <div className="text-center">
                <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                  Browse by category
                </h2>
                <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Find the job that’s perfect for you.
                </p>
              </div>
              <div className="box-swiper mt-50">
                <CategorySlider db={db} />
              </div>
            </div>
          </div>
        </section>

        <div className="section-box mb-30">
          <div className="container">
            <div className="box-we-hiring">
              <div className="text-1">
                <span className="text-we-are">Employers</span>
                <span className="text-hiring">Hiring</span>
              </div>
              <div className="text-2">
                Posting Jobs
                <br /> <span className="color-brand-1">Made easy</span>
              </div>
              <div className="text-3">
                <Link to="/job">
                  <div className="btn btn-apply btn-apply-icon">Post a job</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="section-box overflow-visible mt-100 mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="box-image-job">
                  <figure className="wow animate__animated animate__fadeIn">
                    <img
                      alt="findAJobUSA"
                      src="assets/imgs/page/homepage1/img1.png"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="content-job-inner text-start">
                  <span className="color-brand-2 text-32">
                    Thousands of Jobs.
                  </span>
                  <h2 className="text-42 wow animate__animated animate__fadeInUp">
                    Find The One That’s <br />
                    <span className="color-brand-3 highlighted">Right</span> For
                    You
                  </h2>
                  <div className="mt-40 pr-50 text-md-lh28 wow animate__animated animate__fadeInUp">
                    We have thousands of domestic jobs across multiple
                    industries. Click the button below to search our robust job
                    directory today.
                  </div>
                  <div className="mt-40">
                    <div className="wow animate__animated animate__fadeInUp">
                      <a href="/jobs" className="btn btn-default">
                        Search Jobs
                      </a>

                      <Link to="/about" className="btn btn-link">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box overflow-visible mt-50 mb-50">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="color-brand-2">
                    <span className="count">10</span>
                    <span> K+</span>
                  </h1>
                  <h5>Jobs Posted</h5>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="color-brand-2">
                    <span className="count">1,000</span>
                    <span> +</span>
                  </h1>
                  <h5>Recent Positions Filled</h5>
                </div>
              </div>
              {/*<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">*/}
              {/*  <div className="text-center">*/}
              {/*    <h1 className="color-brand-2">*/}
              {/*      <span className="count">86</span>*/}
              {/*      <span> +</span>*/}
              {/*    </h1>*/}
              {/*    <h5>Companies</h5>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">*/}
              {/*  <div className="text-center">*/}
              {/*    <h1 className="color-brand-2">*/}
              {/*      <span className="count">28</span>*/}
              {/*      <span> +</span>*/}
              {/*    </h1>*/}
              {/*    <h5>Happy Clients</h5>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default withFirebase(Home)
