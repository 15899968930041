import { Link } from 'react-router-dom'
import React from 'react'
import moment from 'moment-timezone'
import { getIndustryByKey } from '../../constants/Industries'

const Details = ({ data, printableId }) => {
  if (!data) {
    return null
  }

  const dateUpdated = moment(
    data && data.updatedAt ? data.updatedAt.toDate() : new Date(),
  ).format('MM/DD/YY')

  const dateCreated = moment(
    data && data.createdAt ? data.createdAt.toDate() : new Date(),
  ).format('MM/DD/YY')

  let expirationDate = moment(
    data.expiration ? data.expiration.toDate() : new Date(),
  )
    .utc()
    .subtract(8, 'hours')
    .format('MM/DD/YY')

  const expirationDateTest = moment(
    data.expiration ? data.expiration.toDate() : new Date(),
  )

  console.log(
    'Expiration date in db:',
    expirationDateTest.format('YYYY-MM-DD HH:mm:ss'),
  )

  console.log(
    'Expiration date UTC:',
    expirationDateTest.utc().format('YYYY-MM-DD HH:mm:ss'),
  )

  console.log(
    'Expiration date PST:',
    expirationDateTest.utc().subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss'),
  )

  console.log('today: ', moment().subtract(8, 'hours'))
  console.log('today UTC: ', moment().utc().subtract(8, 'hours'))

  // console.log(
  //   'expired with today UTC:',
  //   moment().utc().isAfter(expirationDateTest),
  // )

  // console.log(
  //   'expired with today PST:',
  //   moment().utc().subtract(8, 'hours').isAfter(expirationDateTest),
  // )
  //
  const isExpired = moment()
    .utc()
    .subtract(8, 'hours')
    .isAfter(expirationDateTest)

  if (isExpired) {
    return (
      <div className="row">
        {isExpired && (
          <p style={{ fontSize: 20, color: 'red' }} className={'text-center'}>
            <b>This job post has expired or been removed</b>
          </p>
        )}
      </div>
    )
  }

  return (
    <div className="row">
      <>
        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="border-bottom job-overview">
            <div className="border-bottom pb-15 mb-30">
              <div className="row mt-10">
                <div className="col-lg-8 col-md-12">
                  <h3>{data.title}</h3>
                  <div className="mt-10 mb-15">
                    <span className="card-briefcase">{data?.company}</span>
                    <span className="card-location">{`${data.city}, ${data.state}`}</span>
                    {dateCreated !== '' && (
                      <span className="card-time">{dateCreated}</span>
                    )}
                  </div>
                </div>
                {printableId && (
                  <div className="col-lg-4 col-md-12 text-lg-end">
                    <Link
                      className="btn btn-pdf-icon"
                      to={`/print/${printableId}`}
                    >
                      Print Job
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <h5 className=" pb-15 mb-30">Overview</h5>
            <div className="row">
              {data?.industry && (
                <div className="col-md-6 d-flex">
                  <div className="sidebar-icon-item">
                    <img
                      src="/assets/imgs/page/job-single/industry.svg"
                      alt="findAJobUSA"
                    />
                  </div>
                  <div className="sidebar-text-info ml-10">
                    <span className="text-description industry-icon mb-10">
                      Industry
                    </span>
                    <strong className="small-heading">
                      {getIndustryByKey(data.industry).value}
                    </strong>
                  </div>
                </div>
              )}

              {dateCreated && (
                <div className="col-md-6 d-flex mt-sm-15">
                  <div className="sidebar-icon-item">
                    <img
                      src="/assets/imgs/page/job-single/updated.svg"
                      alt="findAJobUSA"
                    />
                  </div>
                  <div className="sidebar-text-info ml-10">
                    <span className="text-description jobtype-icon mb-10">
                      Posted
                    </span>
                    <strong className="small-heading">{dateCreated}</strong>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt-25">
              {data?.salary && (
                <div className="col-md-6 d-flex mt-sm-15">
                  <div className="sidebar-icon-item">
                    <img
                      src="/assets/imgs/page/job-single/salary.svg"
                      alt="findAJobUSA"
                    />
                  </div>
                  <div className="sidebar-text-info ml-10">
                    <span className="text-description salary-icon mb-10">
                      Salary
                    </span>
                    <strong className="small-heading">{data.salary}</strong>
                  </div>
                </div>
              )}

              {data?.city && data?.state && (
                <div className="col-md-6 d-flex mt-sm-15">
                  <div className="sidebar-icon-item">
                    <img
                      src="/assets/imgs/page/job-single/location.svg"
                      alt="findAJobUSA"
                    />
                  </div>
                  <div className="sidebar-text-info ml-10">
                    <span className="text-description mb-10">Location</span>
                    <strong className="small-heading">
                      {data.city}, {data.state}
                    </strong>
                  </div>
                </div>
              )}

              {expirationDate && (
                <div className="col-md-6 d-flex">
                  <div className="sidebar-icon-item">
                    <img
                      src="/assets/imgs/page/job-single/deadline.svg"
                      alt="findAJobUSA"
                    />
                  </div>
                  <div className="sidebar-text-info ml-10">
                    <span className="text-description mb-10">Expiration</span>
                    <strong className="small-heading">{expirationDate}</strong>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="content-single"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          <div className="author-single">
            <span>{data.company}</span>
          </div>
        </div>

        {printableId && (
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
            <div className="sidebar-border">
              <div className="sidebar-heading">
                <div className="avatar-sidebar">
                  <div className="sidebar-info">
                    <span className="sidebar-company">
                      Application Directions
                    </span>
                  </div>
                </div>
              </div>
              <div className="sidebar-list-job">
                <div className="content-single">{data?.directions}</div>
                {data?.directions && data?.application_url && <br />}
                {data?.application_url && (
                  <Link
                    to={data?.application_url}
                    target={'_blank'}
                    className="btn btn-default btn-shadow hover-up"
                  >
                    Apply Here
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default Details
